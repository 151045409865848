import React from 'react';

import { APP_NAME } from '../services/constants';

const Splash = ({ noWidth }) => {
	document.title = APP_NAME;

	return (
		<div id={noWidth ? 'suspense-loader' : 'suspense-loader-width'}>
			<div id="status">
				<div className="spinner-border text-primary avatar-sm" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>
	);
};

export default Splash;
