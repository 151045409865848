// import { io } from 'socket.io-client';

import { SOCKET_API } from './constants';
// import { setOnlineUsers } from '../redux/slices/account';

let socket;

export const initSocket = (token, userId) => {
	console.log(`socket initiatied: ${SOCKET_API}`);

	// socket = io(SOCKET_API, {
	// 	auth: { token },
	// 	query: { userId },
	// 	transports: ['websocket'],
	// });
	// if (socket) {
	// 	socket.on('connect', () => {
	// 		console.log(`connected to socket.io: ${socket.connected}: ${socket.id}`);
	// 	});

	// 	socket.io.on('reconnect', () => {
	// 		console.log(`re-connected to socket.io`);
	// 	});

	// 	socket.on('disconnect', reason => {
	// 		console.log(`user disconnected: ${reason}`);
	// 	});
	// }
};

export const subscribeIO = dispatch => {
	// if (socket) {
	// 	users online
	// 	socket.on('users:online', data => {
	// 		dispatch(setOnlineUsers(data.users));
	// 	});
	// }
};

export const emit = (event, data) => {
	// if (socket) {
	// 	socket.emit(event, data);
	// }
};

export const disconnectSocket = () => {
	// if (socket) {
	// 	socket.disconnect();
	// }
};

export const getSocket = () => socket;
