import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { checkPermission } from '../services/utilities';

const SidebarMenuData = () => {
	const [isCurrentState, setIsCurrentState] = useState('Dashboard');

	const permissions = useSelector(state => state.account.permissions);

	useEffect(() => {
		document.body.classList.remove('twocolumn-panel');
		if (
			isCurrentState === 'Dashboard' ||
			isCurrentState === 'Products' ||
			isCurrentState === 'Orders' ||
			isCurrentState === 'Customers' ||
			isCurrentState === 'Staffs' ||
			isCurrentState === 'Invoices' ||
			isCurrentState === 'Transactions' ||
			isCurrentState === 'AuditLog' ||
			isCurrentState === 'Settings'
		) {
			document.body.classList.add('twocolumn-panel');
		}
	}, [isCurrentState]);

	const menuItems = [
		{
			label: 'Main Home',
			isHeader: true,
			permission: checkPermission(permissions, 'can-see-dashboard'),
		},
		{
			id: 'dashboard',
			label: 'Dashboard',
			icon: 'ri-dashboard-line',
			link: '/',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Dashboard');
			},
			permission: checkPermission(permissions, 'can-see-dashboard'),
		},
		{
			label: 'Ecommerce',
			isHeader: true,
			permission:
				checkPermission(permissions, 'can-see-products') ||
				checkPermission(permissions, 'can-see-orders'),
		},
		{
			id: 'products',
			label: 'Products',
			icon: 'ri-shopping-bag-3-line',
			link: '/products',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Products');
			},
			permission: checkPermission(permissions, 'can-see-products'),
		},
		{
			id: 'orders',
			label: 'Orders',
			icon: 'ri-shopping-cart-2-line',
			link: '/orders',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Orders');
			},
			permission: checkPermission(permissions, 'can-see-orders'),
		},
		{
			label: 'Users',
			isHeader: true,
			permission:
				checkPermission(permissions, 'can-see-customers') ||
				checkPermission(permissions, 'can-see-staffs'),
		},
		{
			id: 'customers',
			label: 'Customers',
			icon: 'ri-user-3-line',
			link: '/customers',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Customers');
			},
			permission: checkPermission(permissions, 'can-see-customers'),
		},
		{
			id: 'staffs',
			label: 'Staffs',
			icon: 'ri-group-line',
			link: '/staffs',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Staffs');
			},
			permission: checkPermission(permissions, 'can-see-staffs'),
		},
		{
			label: 'Finance',
			isHeader: true,
			permission:
				checkPermission(permissions, 'can-see-invoices') ||
				checkPermission(permissions, 'can-see-transactions') ||
				checkPermission(permissions, 'can-see-refunds'),
		},
		{
			id: 'invoices',
			label: 'Invoices',
			icon: 'ri-wallet-line',
			link: '/invoices',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Invoices');
			},
			permission: checkPermission(permissions, 'can-see-invoices'),
		},
		{
			id: 'transactions',
			label: 'Transactions',
			icon: 'ri-bank-card-line',
			link: '/transactions',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Transactions');
			},
			permission: checkPermission(permissions, 'can-see-transactions'),
		},
		{
			id: 'refunds',
			label: 'Refunds',
			icon: 'ri-refund-line',
			link: '/refunds',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Refunds');
			},
			permission: checkPermission(permissions, 'can-see-refunds'),
		},
		{
			label: 'System Settings',
			isHeader: true,
			permission:
				checkPermission(permissions, 'can-see-audit-logs') ||
				checkPermission(permissions, 'can-see-store-settings') ||
				checkPermission(permissions, 'can-see-store-information') ||
				checkPermission(permissions, 'can-see-general-information') ||
				checkPermission(permissions, 'can-see-shipping-zones') ||
				checkPermission(permissions, 'can-see-roles') ||
				checkPermission(permissions, 'can-see-permissions') ||
				checkPermission(permissions, 'can-see-brands') ||
				checkPermission(permissions, 'can-see-attributes') ||
				checkPermission(permissions, 'can-see-security') ||
				checkPermission(permissions, 'can-see-payment-setup') ||
				checkPermission(permissions, 'can-see-mailer'),
		},
		{
			id: 'audit',
			label: 'Audit Log',
			icon: 'ri-folders-line',
			link: '/audit-log',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('AuditLog');
			},
			permission: checkPermission(permissions, 'can-see-audit-logs'),
		},
		{
			id: 'settings',
			label: 'Settings',
			icon: 'ri-settings-3-line',
			link: '/settings',
			click: function (e) {
				e.preventDefault();
				setIsCurrentState('Settings');
			},
			permission:
				checkPermission(permissions, 'can-see-store-settings') ||
				checkPermission(permissions, 'can-see-store-information') ||
				checkPermission(permissions, 'can-see-general-information') ||
				checkPermission(permissions, 'can-see-shipping-zones') ||
				checkPermission(permissions, 'can-see-roles') ||
				checkPermission(permissions, 'can-see-permissions') ||
				checkPermission(permissions, 'can-see-brands') ||
				checkPermission(permissions, 'can-see-attributes') ||
				checkPermission(permissions, 'can-see-security') ||
				checkPermission(permissions, 'can-see-payment-setup') ||
				checkPermission(permissions, 'can-see-mailer'),
		},
	];
	return <>{menuItems}</>;
};

export default SidebarMenuData;
