import React from 'react';

import { APP_NAME } from '../services/constants';

const Footer = () => {
	return (
		<footer className="footer galaxy-border-none">
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6">
						{new Date().getFullYear()} &copy; {`${APP_NAME} Admin`}.
					</div>
					<div className="col-sm-6">
						<div className="text-sm-end d-none d-sm-block">
							App by{' '}
							<a href="https://nerdlab.ltd/" target="_blank" rel="noreferrer">
								Nerdlab
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
