import React from 'react';

import { leftSidebarViewTypes } from '../services/layout';

const DetachMenu = ({ leftSidebarView, onChangeLeftSidebarViewType }) => {
	const viewType =
		leftSidebarView === leftSidebarViewTypes['DEFAULT']
			? leftSidebarViewTypes['DETACHED']
			: leftSidebarViewTypes['DEFAULT'];

	return (
		<div className="ms-1 header-item d-none d-sm-flex">
			<button
				onClick={() => onChangeLeftSidebarViewType(viewType)}
				type="button"
				className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
			>
				{leftSidebarView === leftSidebarViewTypes['DEFAULT'] ? (
					<i className="bx bx-collapse fs-22"></i>
				) : (
					<i className="bx bx-expand fs-22"></i>
				)}
			</button>
		</div>
	);
};

export default DetachMenu;
