import { createSlice } from '@reduxjs/toolkit';

export const productReducer = createSlice({
	name: 'product',
	initialState: {
		published: 0,
		deleted: 0,
		pickups: 0,
	},
	reducers: {
		setProductsPublished: (state, action) => {
			state.published = action.payload;
		},
		setProductsDeleted: (state, action) => {
			state.deleted = action.payload;
		},
		setDeliveryPickup: (state, action) => {
			state.pickups = action.payload;
		},
	},
});

export const { setProductsPublished, setProductsDeleted, setDeliveryPickup } =
	productReducer.actions;

export default productReducer.reducer;
