import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { request } from '../../services/http';
import { FETCH_COUTRIES_API } from '../../services/api';

export const fetchUtilities = createAsyncThunk('fetchUtilities', async () => {
	let [countries] = await Promise.all([request(FETCH_COUTRIES_API)]);
	return countries?.result || [];
});

export const utilitySlice = createSlice({
	name: 'utility',
	initialState: {
		countries: [],
	},
	reducers: {},
	extraReducers(builder) {
		builder.addCase(
			fetchUtilities.fulfilled,
			(state, action) => void (state.countries = action.payload)
		);
	},
});

export default utilitySlice.reducer;
