import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '../hooks/auth';
import {
	loginUser,
	setAuthMethod,
	setConnected,
	setPermissions,
} from '../redux/slices/account';
import { TOKEN_COOKIE, TOKEN_EXPIRATION } from '../services/constants';
import LocalStorage from '../services/storage';
import { fetchSettings } from '../redux/slices/setting';
import { initSocket, subscribeIO } from '../services/socket';

const storage = new LocalStorage();

const AuthProvider = ({ children }) => {
	const user = useSelector(state => state.account.user);

	const dispatch = useDispatch();

	const setTokens = data => {
		const { user, auth } = data;
		const permissions = user.roles.reduce(
			(permissions, item) => [...permissions, ...item.permissions],
			[]
		);
		dispatch(loginUser(user));
		dispatch(setAuthMethod(auth));
		dispatch(setPermissions(permissions));

		if (data.token) {
			storage.setItem(TOKEN_COOKIE, data.token);
			storage.setItem(TOKEN_EXPIRATION, data.expires_in);

			// start socket connection
			dispatch(setConnected(true));
			initSocket(data.token, data.user?.id);
			subscribeIO(dispatch);

			// fetch settings
			setTimeout(() => dispatch(fetchSettings()), 5000);
		}
	};

	return (
		<AuthContext.Provider value={{ user, setAuthUser: setTokens }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
