import layoutReducer from './layout';
import settingReducer from './setting';
import accountReducer from './account';
import utilityReducer from './utility';
import productReducer from './product';

const reducers = {
	layout: layoutReducer,
	setting: settingReducer,
	account: accountReducer,
	utility: utilityReducer,
	product: productReducer,
};

export default reducers;
