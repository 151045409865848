import { createSlice } from '@reduxjs/toolkit';

import { request } from '../../services/http';
import { FETCH_SETTINGS_API } from '../../services/api';
import { signOut } from './account';

export const fetchSettings = () => async dispatch => {
	try {
		const rs = await request(FETCH_SETTINGS_API);
		dispatch(setSettings(rs.result));
		dispatch(togglePreloading(false));
	} catch (e) {
		dispatch(signOut());
		dispatch(togglePreloading(false));
	}
};

export const settingSlice = createSlice({
	name: 'setting',
	initialState: {
		preloading: true,
		is404: false,
		settings: [],
	},
	reducers: {
		togglePreloading: (state, action) => {
			state.preloading = action.payload;
		},
		toggleIs404: (state, action) => {
			state.is404 = action.payload;
		},
		setSettings: (state, action) => {
			state.settings = action.payload;
		},
	},
});

export const { togglePreloading, toggleIs404, setSettings } =
	settingSlice.actions;

export default settingSlice.reducer;
